import Image from 'next/image';
import classNames from 'classnames';
import Link from 'next/link';
import styles from './index.module.less';
import RetroTitle from '@/components/Common/RetroTitle';
import { AppSettings, lowerName } from '@/shared/app-common';
import { useTranslation } from 'next-i18next';
import { CommonBaseContent } from '../CommonBackContent';
import { AllSupportLanguages } from '@/shared/language';
// import FooterListContent from '@/components/Common/FooterListContent';
import { useRouter } from 'next/router';
import { Divider } from 'antd';
// import dayjs from 'dayjs';

const GlobalFooter = ({ show = true, className }: { show?: boolean; className?: string }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { pathname, asPath, query } = router

  const switchLanguage = (locale: string) => {
    router.push({ pathname, query }, asPath, { locale })
  }
  if (!show) {
    return <></>;
  }
  const cls = classNames(styles.globalFooterWarp, className);

  const listData = [
    {
      title: t('Home'),
      link: `/`,
    },
    // {
    //   title: t('Influencers Square'),
    //   link: '/influencers',
    // },
    {
      title: t('Sample Guide'),
      path: '/sample-guide',
    },
    {
      title: t('Crisis Database'),
      path: '/crisis-database',
    },
    {
      title: t('Plan'),
      link: `/plan`,
    },
    {
      title: t('FAQ'),
      link: `/faq`,
    },
  ];

  const bottomContainerList = [
    {
      title: t('privacyPolicy'),
      link: `/privacy-policy`,
    },
    {
      title: t('termsOfUse'),
      link: `/terms-of-use`,
    },
  ];

  return (
    <div className={cls}>
      <CommonBaseContent>
        <div className={styles.line} />
        <div className={styles.mainConetent}>
          <div className={styles.logConatiner}>
            <Link href="/">
              <RetroTitle
                text={
                  <div style={{ height: '32px' }}>
                    <Image
                      style={{ objectFit: 'contain', marginRight: '10px' }}
                      src={AppSettings.logoPath}
                      alt={AppSettings.name}
                      loading="lazy"
                      width={32}
                      height={32}
                    />
                    <Image
                      src={AppSettings.namePath}
                      alt={AppSettings.name}
                      height={32}
                      width={100}
                      style={{
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                }
                fontSize={28}
                showAlert={true}
              />
            </Link>
            <p>{t(`${lowerName}_Title`)}</p>
          </div>
          <div className={styles.otherConatiner}>
            <div className={styles.linkContainer}>
              {listData.map((item, index) => {
                return (
                  <span
                    key={`${item?.link}-${index}`}
                    onClick={() => {
                      router.push(item?.link);
                    }}
                  >
                    {item?.title}
                  </span>
                );
              })}
            </div>

            {/* <div className={styles.copyrightContainer}>
              {`Copyright ©2024 ${AppSettings.name}. All Rights Reserved.`}
            </div> */}
          </div>
        </div>
        <div className={styles.bottomContent}>
          <ul className={styles.languageContainer}>
            {AllSupportLanguages.map((item, index) => {
              return (
                <li key={index}>
                  <span
                    onClick={() => switchLanguage(item?.code)}
                    style={{
                      color:
                        item?.code === router.locale
                          ? `var(--app-primary-color)`
                          : `var(--app-text-color)`,
                    }}
                  >
                    {item?.name}
                  </span>
                </li>
              );
            })}
          </ul>
          <div className={styles.rightContainer}>
            {bottomContainerList.map((item, index) => {
              return (
                <span key={item.title}>
                  {(index + 1) % 2 === 0 && (
                    <Divider type="vertical" style={{ borderColor: 'black', margin: '0 30px' }} />
                  )}
                  <span
                    onClick={() => {
                      router.push(item?.link);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {item.title}
                  </span>
                </span>
              );
            })}
          </div>
        </div>
      </CommonBaseContent>
    </div>
  );
};

export default GlobalFooter;
